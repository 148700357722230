<template>
  <div class="app-contianer">
    <div class="page-item themes-box">
      <div class="title f40"><strong>皮肤切换</strong></div>
      <div class="content">
        <div class="item" v-for="item in themesMap" :key="item.value" @click="changeThemes(item)">
          <!-- <div class="color mainBgColor"></div> -->
          <div class="color" :style="{backgroundColor:item.color}"></div>
          <div class="label  f34">{{item.label}}</div>
        </div>
      </div>
    </div>
    <!-- <div class="page-item">
      <div class="title f38">切换主题</div>
      <div class="themes-box">
        <van-radio-group v-model="theme" direction="horizontal">
          <van-radio v-for="item in themesMap" :key="item.value" :name="item.value">
            <span class="themeColor" :style="{backgroundColor:item.color}"></span>
            {{item.label}}
          </van-radio>
        </van-radio-group>
      </div>
    </div> -->

    <div class="submitBut" @click="save()">保存</div>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import { mallApi } from '../../api'

export default {
  name: 'changeTheme',
  data() {
    return {
      theme: ''
    }
  },
  computed: {
    ...mapState('user', ['themesMap']),
  },
  created() { },
  methods: {
    changeThemes(item) {
      const body = document.body
      body.className = item.value
      this.theme = item.value
    },

    save() {
      let params = {
        trxCode: 'XF179',
        theme: this.theme
      }
      mallApi(params).then(res => {
        if (res.rspCode === '0000') {
          this.$toast('保存成功')
        }
      })
    }
  }
}
</script>

<style lang='less' scoped>
.page-item {
  > .title {
    margin-bottom: 12px;
  }
}
.themes-box {
  margin-top: 12px;
}
.themeColor {
  width: 10vw;
  height: 4vw;
  border-radius: 4px;
  display: inline-block;
}
.themes-box {
  .content {
    display: flex;
    justify-content: space-around;
    margin: 12px 0;
  }
  .item {
    text-align: center;
  }
  .item .color {
    width: 10vw;
    height: 4vw;
    border-radius: 4px;
  }
  .label {
    margin-top: 2px;
  }
}
</style>